<template>
	<div class="rental-united-group">
		<Controls
			ref="Controls"
			:filterByLocation="location===null"
			:locations="locations"
			:startArea="startArea"
			:startFrom="startFrom != '0' ? startFrom : null"
			:startTo="startTo != '0' ? startTo : null"
			:startGuests="startGuests"
			:startRooms="startRooms"
			:disabled="!availabilityCalendarsReady"
			@filter-changed="filterChanged"
		/>
		<div class="rentals-united-filter ">
			<div class="rentals-united-left">
				<div class="properties-container" :class="{ 'properties-loading': loading, 'no-map': !showMap}">
					<SingleProperty
						v-for="property in filteredProperties"
						:key="property.ID"
						:post="property"
					/>
				</div>
			</div>
			<div class="rentals-united-right" :class="{ 'nomap': !showMap}">
				<Map
					@map-bounds-changed="mapBoundsChanged"
					@user-map-interaction="resetArea"
					:visible-properties="visibleProperties"
					:properties="properties"
					:zoom="zoom"
					:center="center"
					:hovered="hovered"
				/>
			</div>
		</div>
	</div>
	
</template>

<script>
import SingleProperty from './SingleProperty.vue'
import Controls from './Controls.vue'
import Map from './Map.vue'
import axios from 'axios'
import moment from 'moment'

export default {
	name: 'Ru',

	watch: {
		filter: {
			handler() {
				if (this.backendFilter === true) {
					this.getProperties()
				}
			},
			deep: true
		}
	},

	data() {
		return {
			filter: {
				area: null,
				guests: 0,
				rooms: 0,
				from: null,
				to: null
			},
			showMap: true,
			loading: true,
			properties: [],
			mapBounds: null,
			center: {
				lat: 38.72,
				lng: -9.16
			},
			zoom: 8,
			hovered: null,
			availabilityCalendarsReady: false
		}
	},

	props: {
		backendFilter: {
			type: Boolean, 
			default: false
		},
		locations: {
			type: Array,
			default: () => []
		},
		location: {
			type: Number,
			default: null
		},
		startArea: {
			type: Number,
			default: 0
		},
		startGuests: {
			type: Number,
			default: 0
		},
		startRooms: {
			type: Number,
			default: 0
		},
		startFrom: {
			type: String,
			default: null
		},
		startTo: {
			type: String,
			default: null
		},
		gmap: {
			type: String,
			default: null
		},
		pluginPath: {
			type: String,
			required: true
		},
		collection: {
			type: Number,
			default: null
		}
	},

	components: {
		SingleProperty,
		Controls,
		Map
	},

	computed: {
		filteredProperties() {
			if (this.backendFilter === true) return this.properties

			let result = this.properties
			if (this.filter.guests > 0) {
				result = result.filter(p => p.guests >= this.filter.guests)
			}
			if (this.filter.rooms > 0) {
				result = result.filter(p => p.rooms >= this.filter.rooms)
			}
			if (this.filter.area !== null && this.filter.area > 0) {
				result = result.filter(p => {
					return p.location.map(l => l.term_id).includes(this.filter.area) || 
						p.location.map(l => l.parent).includes(this.filter.area)
				})
			}
			if (this.mapBounds !== null) {
				result = result.filter(p => {
					return p.coordinates.Latitude >= this.mapBounds.SW.lat
						&& p.coordinates.Latitude <= this.mapBounds.NE.lat
						&& p.coordinates.Longitude <= this.mapBounds.NE.lng
						&& p.coordinates.Longitude >= this.mapBounds.SW.lng
				})
			}
			if (this.filter.from && this.filter.to && this.filter.from !== '0' && this.filter.to !== '0') {
				result = result.filter(p => this.checkAvailability(p, this.filter.from, this.filter.to))
			}
			return result
		},

		visibleProperties() {
			return this.filteredProperties.map(p => p.ID)
		}
	},

	created() {
		this.filter = {
			area: this.startArea,
			guests: this.startGuests,
			rooms: this.startRooms,
			from: this.startFrom,
			to: this.startTo
		}
		this.getProperties()
	},

	methods: {
		filterChanged(payload) {
			if (this.filter.hasOwnProperty(payload.property)) {
				this.filter[payload.property] = payload.value
			}
			if (payload.property === 'area') {
				this.rePositionMap(payload.value)
			}
		},

		rePositionMap(locationId) {
			let location = this.locations.find(l => l.term_id === locationId)
			if (location && location.lat && location.lng) {
				this.center = {
					lat: parseFloat(location.lat),
					lng: parseFloat(location.lng)
				}
				if (location && location.zoom) {
					this.zoom = parseInt(location.zoom)
				}
			}
		},

		getProperties() {
			this.loading = true
			let formData = new FormData
			formData.append('action', 'filter')
			formData.append('nonce', window.ru.nonce)
			if (this.backendFilter === true) {
				for (let key in this.filter) {
					formData.append(key, this.filter[key])
				}
			}
			if (this.collection) {
				formData.append('collection', this.collection)
			}
			if (this.location) {
				formData.set('area', this.location)
			}
			axios.post(window.ru.ajax_url, formData).then(res => {
					this.properties = res.data.properties
					this.loading = false
					this.properties.forEach((p, k) => this.getAvailabilityCalendar(p.ID, k))
				})
				.catch(err => {
					console.log(err)
					this.loading = false
				})
		},

		mapBoundsChanged(bounds) {
			this.mapBounds = bounds
		},

		resetArea() {
			this.$refs.Controls.resetArea()
		},

		getAvailabilityCalendar(wpID, key) {
			let formData = new FormData()
			formData.append('nonce', window.ru.nonce)
			formData.append('action', 'get_availability_calendar')
			formData.append('wpID', wpID)
			axios.post(window.ru.ajax_url, formData)
				.then(res => {
					this.properties[key].availabilityCalendar = res.data.availabilityCalendar
					this.availabilityCalendarsReady = this.filteredProperties.filter(p => p.availabilityCalendar).length === this.filteredProperties.length
				})
				.catch(err => console.log(err))
		},

		checkAvailability(property, from, to) {
			if (from > to) {
				let swap = to
				to = from
				from = swap
			}
			let stay = moment(to).diff(moment(from), 'days')
			if (stay > 50) return false
			let clone = moment(from).clone()
			while (clone < moment(to)) {
				let dayData = property.availabilityCalendar ? property.availabilityCalendar.find(elt => elt.Date.Date === clone.format('YYYY-MM-DD')) : null
				if (dayData && (dayData.IsBlocked === 'true' || stay < parseInt(dayData.MinStay))) return false
				clone.add(1, 'days')
			}
			return true
		},

		toggleMap(e) {
			e.preventDefault()
			this.showMap = !this.showMap;
		}
	}
}

</script>

<style>
.rentals-united-filter {
	display: flex;
}
@media screen and (max-width: 1100px) {
	.rentals-united-filter {
		display: block;
	}
}
.rentals-united-left {
	flex: 75%;
}
.rentals-united-right {
	flex: 25%;
}
.properties-container {
	display: flex;
	flex-wrap: wrap;
}
.properties-loading {
	opacity: .2;
}
</style>

<style>

</style>