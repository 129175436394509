<template>
	<div class="ru-controls">
		<div class="ru-controls__inner">
			<div  class="ru-controls__inner-wrap">
				<div v-if="filterByLocation">
					<label class="ru-controls__label">Area</label>
					<select v-model="area" class="ru-controls__select ru-controls__select--area">
						<option value="0">area</option>
						<option v-for="location in locations" :key="location.term_id" :value="location.term_id">
							<span v-if="location.parent > 0"> - </span>
							{{ location.name }}
						</option>
					</select>
				</div>
				<div>
					<label class="ru-controls__label">Guests</label>
					<select v-model="guests" class="ru-controls__select ru-controls__select--guests">
						<option value="0">no of guests</option>
						<option v-for="index in 10" :key="index" :value="index">
							{{ index }} {{ index == 1 ? 'guest' : 'guests' }}
						</option>
					</select>
				</div>
				<div>
					<label class="ru-controls__label">Rooms</label>
					<select v-model="rooms" class="ru-controls__select ru-controls__select--rooms">
						<option value="0">no of rooms</option>
						<option v-for="index in 6" :key="index" :value="index">
							{{ index }} {{ index == 1 ? 'room' : 'rooms' }}
						</option>
					</select>
				</div>
				<div>
					<label class="ru-controls__label">Dates</label>
					<input
						placeholder="Arrival | Departure"
						autocomplete="off"
						
						class="ru-controls__range"
						id="ru-controls__range"
						@click="toggleWidth()"
					>
				</div>
				<a href="#" @click="clear" class="ru-controls__clear-button">Reset</a>
			</div>
			<div class="ru-controls__toggle-map-wrap">
				Map
				<a href="#" @click="$parent.toggleMap" class="ru-controls__toggle-map" :class="$parent.showMap ? 'ru-controls__toggle-map--active' : ''"></a>
			</div>
		</div>
	</div>
</template>

<script>


import moment from 'moment';


export default {
	name: 'Controls',

	props: {
		locations: {
			type: Array,
			default: () => []
		},
		startArea: {
			type: Number,
			default: 0
		},
		startGuests: {
			type: Number,
			default: 0
		},
		startRooms: {
			type: Number,
			default: 0
		},
		startFrom: {
			type: String,
			default: null
		},
		startTo: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		filterByLocation: {
			type: Boolean,
			default: true
		}
	},

	watch: {
		area(value) {
			this.$emit('filter-changed', { property: 'area', value: value })
		},
		rooms(value) {
			this.$emit('filter-changed', { property: 'rooms', value: value })
		},
		guests(value) {
			this.$emit('filter-changed', { property: 'guests', value: value })
		},
		dateFrom(value) {
			this.$emit('filter-changed', { property: 'from', value: value })
		},
		dateTo(value) {
			this.$emit('filter-changed', { property: 'to', value: value })
		}
	},

	data() {
		return {
			area: null,
			guests: null,
			rooms: null,
			from: null,
			to: null,
			picker: null
		}
	},

	computed: {
		dateTo() {
			return this.to ? moment(this.to).format('YYYY-MM-DD') : null
		},
		dateFrom() {
			return this.from ? moment(this.from).format('YYYY-MM-DD') : null
		}
	},

	mounted() {
		let that = this
		this.picker = new window.Lightpick({
			field: document.getElementById('ru-controls__range'),
			numberOfMonths: 2,
			singleDate: false,
			tooltipNights: true,
			disabledDatesInRange: false,
			minDate: moment(),
			repick: true,
			autoclose: false,
			footer: true,
			onClose: function callbackTest() {
				document.querySelector('.lightpick').classList.remove('width-100')
			},
			onSelect: function(start, end) {
				console.log('onselect')
				if (start) that.from = start
				if (end) that.to = end
			}
		})
		this.area = this.startArea
		this.guests = this.startGuests
		this.rooms = this.startRooms
		this.from = this.startFrom
		this.to = this.startTo
	},

	methods: {
		resetArea() {
			this.area = 0
		},
		clear(e) {
			e.preventDefault()
			this.area = 0
			this.guests = 0
			this.rooms = 0
			this.from = null
			this.to = null
			this.picker.setDateRange(null, null)
		},
		toggleWidth() {
			console.log('toggle');
			document.querySelector('.lightpick').classList.add('width-100')
		}
	}
}

</script>

<style>
.ru-controls__range-disabled {
	opacity: .2;
}
</style>
