<template>
	<a @click="toProperty" class="single-property-component">
		<div @mouseover="hovered(post.ID)" @mouseleave="hovered(null)" class="single-property-component__inner">
			<div class="single-property-component__image">
				<Slider v-if="imagesForSlider.length > 1" :images="imagesForSlider" />
				<img v-else  :src="post.thumbnail">
			</div>
			<div class="single-property-component__title">
				<h3>{{ post.post_title }}</h3>
			</div>
			<div class="single-property-component__content">
				<p>{{ post.rooms | plural('Room') }} | {{ post.guests | plural('Guest') }} |  From {{ post.price }}€ </p>
				<span @click.prevent.stop="toggleLike" :class="{ 'single-property-component__heart-liked': liked }" class="single-property-component__heart">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<g>
							<path fill="none" d="M0 0h24v24H0z"/>
							<path d="M21.179 12.794l.013.014L12 22l-9.192-9.192.013-.014A6.5 6.5 0 0 1 12 3.64a6.5 6.5 0 0 1 9.179 9.154z"/>
						</g>
					</svg>
				</span>
				
			</div>
		</div>
	</a>
</template>

<script>
import Slider from './Slider.vue'

export default {
	name: 'SingleProperty',
	components: {
		Slider
	},

	props: {
		post: {
			type: Object,
			default: () => {}
		},
	},

	data() {
		return {
			liked: false,
			defaultFilter: {
				area: null,
				guests: 0,
				rooms: 0,
				from: null,
				to: null
			}
		}
	},

	filters: {
		plural(value, text) {
			return value > 1 ? value + ' '+text+'s' : value + ' '+text
		}
	},

	mounted() {
		this.liked = window.rentalsUnited.isPropertyLiked(this.post.ID)
	},

	computed: {
		filter() {
			return this.$parent && this.$parent.filter ? this.$parent.filter : this.defaultFilter
		},
		imagesForSlider() {
			let selected = this.post.images;
			let thumbnail = [this.post.thumbnail, ...selected];
			return thumbnail.filter((value, key) => thumbnail.indexOf(value)===key);
		}
	},

	methods: {
		toggleLike() {
			this.liked = !this.liked
			if (this.liked === true) {
				let from = parseInt(this.filter.from)
				let to = parseInt(this.filter.to)
				window.rentalsUnited.addToSavedProperties({
					ID: this.post.ID,
					post_title: this.post.post_title,
					image: this.post.image,
					images: this.post.images,
					link: this.post.link,
					coordinates: this.post.coordinates,
					from: from !== 0 ? this.filter.from : null,
					to: to !== 0 ? this.filter.to : null,
					guests: parseInt(this.post.guests) || null,
					rooms: parseInt(this.post.rooms) || null,
					price: parseInt(this.post.price) || null
				})
			}
			else {
				window.rentalsUnited.removeFromSavedProperties(this.post.ID)
			}
		},

		toProperty() {
			let url = this.post.link
			let params = {}
			if (this.filter.guests > 0) {
				params.guests = this.filter.guests
			}
			if (this.filter.rooms > 0) {
				params.rooms = this.filter.rooms
			}
			if (this.filter.from && this.filter.to && this.filter.from != '0' && this.filter.to != '0') {
				params.from = this.filter.from
				params.to = this.filter.to
			}
			if (Object.keys(params).length) {
				url += '?' + Object.keys(params).map(k => k + '=' + params[k]).join('&')
			}
			window.location = url
		},

		hovered(id) {
			this.$parent.hovered = id
		}
	}
}

</script>


<style>

.single-property-component {
	flex: 0 0 15em;
	color: #000;
	margin: 10px;
	border-radius: 5px;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.single-property-component:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	transform: scale(1.1);
}
.single-property-component__inner {
	padding: 16px;
}
.single-property-component:visited, .single-property-component:hover {
	color: #000;
}
.single-property-component__heart svg {
	width: 16px;
	height: 16px;
}
.single-property-component__heart svg path:nth-child(2) {
	fill: white;
}
.single-property-component__heart-liked svg path:nth-child(2) {
	fill: #FF6666;
}
.single-property-component__image img {
	width: 100%;
}

</style>